<template>
  <container-list-extend title="Phase">
    <template v-slot:title>
      <div class="p-1 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <h6 class="m-0 ml-3" style="line-height:1rem;">{{ phase.text }}</h6>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small></small>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        @click="updatePhase(phase)"
        deleteBtn
        @delete="deletePhase(phase)"
        :statut="statut"
        :loading="loading"
      >
        <base-form-row row>
          <base-input v-model="phase.text" inputText="Description"></base-input>
        </base-form-row>
        <base-form-row row>
          <base-input-date-picker v-model="phase.start_date" inputText="Date de début"></base-input-date-picker>
          <BaseSlideNumber v-model.number="phase.duration" text="Durée (jrs)" :max="100" :min="0"></BaseSlideNumber>
        </base-form-row>
        <base-form-row row>
          <BaseSlideNumber
            v-model.number="phase.progress"
            text="Progression (%)"
            :max="100"
            :min="0"
          ></BaseSlideNumber>
        </base-form-row>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";

import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseSlideNumber from "@/components/bases/SlideNumber.vue";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInput,
    BaseInputDatePicker,
    BaseSlideNumber
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null
    };
  },
  props: {
    phase: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      _updatePhase: "affaires/updatePhase",
      _deletePhase: "affaires/deletePhase"
    }),
    updatePhase: function(Phase) {
      this.feedback = {};
      this.loading = true;
      this._updatePhase(Phase)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deletePhase: function(Phase) {
      this.feedback = {};
      this.loading = true;
      this._deletePhase(Phase)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css"></style>

<template>
  <div class="d-flex flex-column flex-grow-1 rounded mr-md-3">
    <BaseToolBar title="Planning" subtitle="Liste des phases du projet">
      <div class="d-flex align-items-center">
        <router-link class="mr-2" :to="{ name: 'crm_affaire_gant' }">
          <BaseButton v-tooltip="'Ouvrir le Gant'" :loading="loading" text="Plan" icon="calendar"></BaseButton>
        </router-link>
        <BaseButton
          class="btn btn-primary"
          v-tooltip="'Ajouter une phase'"
          @click="createPhase(id)"
          :loading="loading"
          text="Ajouter"
          icon="plus"
        ></BaseButton>
      </div>
    </BaseToolBar>
    <div v-if="!loading">
      <div v-for="phase in phases" :key="phase.id" class="w-100">
        <planning-phase-item :phase="phase"></planning-phase-item>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import BaseButton from "@/components/bases/Button.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import PlanningPhaseItem from "@/components/affaires/PlanningPhaseItem.vue";

export default {
  components: { BaseToolBar, BaseButton, PlanningPhaseItem },
  data() {
    return {
      active: false,
      loading: false
    };
  },
  methods: {
    ...mapActions({
      _createPhase: "affaires/createPhase",
      _updatePhase: "affaires/updatePhase",
      _deletePhase: "affaires/deletePhase"
    }),
    createPhase(affaire_id) {
      this.loading = true;
      this._createPhase(affaire_id)
        //.then(response => (this.newPhase = response.data))
        .finally(() => (this.loading = false));
    },
    updatePhase(phase) {
      this.loading = true;
      this._updatePhase(phase).finally(() => (this.loading = false));
    },
    deletePhase(phase) {
      this.loading = true;
      this._deletePhase(phase).finally(() => (this.loading = false));
    }
  },
  computed: {
    ...mapGetters({
      ganttData: "affaires/dataForGant"
    }),
    ...mapFields("affaires", ["affaire.id"]),
    ...mapMultiRowFields("affaires", ["phases"]),
    ...mapMultiRowFields("affaires", ["links"])
  }
};
</script>
<style lang="css">
.modal-container-chart {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

.modal-container-chart.active {
  position: absolute;
  background: white;
  padding: 20px;
  z-index: 2000;
}

.container-gantt {
  max-width: 100%;
}

#gantt {
  background: white;
  height: 100vh;
}
</style>
